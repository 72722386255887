const Verdict = ({results}) => {
  let isSpam = false;
  for (const result of results) {
    if (result.category.knownSpam ||
      result.category.knownPhishing ||
      result.category.knownVirus ||
      result.category.knownCompromised) {
        isSpam = true;
        break;
    }
  }
  if (isSpam) {
    return (<span style={{color:'#c62828'}}>Spam</span>);
  }
  return (<span style={{color:'#4caf50'}}>Not Spam</span>);
}

export default Verdict;
