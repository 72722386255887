import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { 
  Box,
  Container,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Report from '@material-ui/icons/Report';
import ReportOff from '@material-ui/icons/ReportOff';
import Check from '@material-ui/icons/Check';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import Timeline from '@proofpoint/fuse-ui/components/Timeline';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import EngineMap from '../EngineMap';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: '1',
    },
  },
  borderless: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const isASESig = (result) => {
  return (result.id === "QhnmdGnfZl10ibMM-eB4" && result.algorithm===22);
}

const AlgorithmResult = ({result}) => {
  if (isASESig(result)) return <ASESigView result={result}/>;
  if (result.category.knownCompromised ||
    result.category.knownPhishing ||
    result.category.knownSpam ||
    result.category.knownVirus
  ) return <SpammyView result={result}/>;
  if (result.dateBecameLegit) return <WasSpammyView result={result}/>;
  return <LegitView result={result}/>;
}

const SpammyView = ({result}) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [summary, err] = EngineMap(result.algorithm);
  const toggleOpen = () => setOpen(!open);
  if (err != null) return <Unrecognized/>;

  return (<React.Fragment>
      <TableRow className={classes.borderless} hover onClick={toggleOpen}>
        <TableCell style={{minWidth:20}}>
          <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{minWidth:20}}>
          <Report color='error'/>
        </TableCell>
        <TableCell>
          <Typography variant="button" color="error">{result.id}:{result.algorithm}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{summary}</Typography>
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <DetailView result={result} style={{display:'flex'}}/>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );

}

const Unrecognized = () => {
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell colSpan={4}>
          Unrecognized analysis engine;
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const WasSpammyView = ({result}) => {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [summary, err] = EngineMap(result.algorithm);
  const toggleOpen = () => setOpen(!open);
  if (err) return <Unrecognized/>;
  return (<React.Fragment>
      <TableRow className={classes.borderless} hover onClick={toggleOpen}>
        <TableCell style={{minWidth:20}}>
          <IconButton aria-label="expand row" size="small" onClick={toggleOpen}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell style={{minWidth:20}}>
          <ReportOff color='error'/>
        </TableCell>
        <TableCell>
          {result.id}:{result.algorithm}
        </TableCell>
        <TableCell>{summary}</TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <DetailView result={result} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const LegitView = ({result}) => {
  
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root} >
        <TableCell style={{minWidth:20}}>
        </TableCell>
        <TableCell style={{minWidth:20}}>
          <Check color='primary'/>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{result.id}:{result.algorithm}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">Not marked as spam</Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const ASESigView = ({result}) => {
  
  const classes = useRowStyles();
  return (
    <React.Fragment>
      <TableRow className={classes.root} >
        <TableCell style={{minWidth:20}}>
        </TableCell>
        <TableCell style={{minWidth:20}}>
          <ReportOff color='error'/>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">{result.id}:{result.algorithm}</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="subtitle1">This fingerprint is for a message that is classified as spam through Automated Signals Extraction. Please log a support ticket with the full message content to request remediation</Typography>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
const DetailView = ({result}) => {
  const formatDate = (d) => {
    if (d == null || d == "") return "Not available";
    const out =  new Date(d);
    return out.toDateString();
  }
  const formatTime = (d) => {
    if (d == null || d == "") return "--";
    const out =  new Date(d);
    return out.toTimeString();
  }
  let id = 0;
  let activities = [];
  activities.push(
    {
      id: id++,
      date: formatDate(result.dateFirstSeen),
      time: formatTime(result.dateFirstSeen),
      icon: <VisibilityIcon />,
      description: "First Seen"
    }
  );
  if (result.recentFeedbackDetails.firstBlock) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.recentFeedbackDetails.firstBlock),
        time: formatTime(result.recentFeedbackDetails.firstBlock),
        icon: <FingerprintIcon />,
        description: "First report"
      }
    );
  }
  if (result.dateBecameSpammy) {
    let identified = "";
    switch (true) {
      case result.category.knownSpam: identified = "Identified as spam"; break;
      case result.category.knownPhishing: identified = "Identified as phishing"; break;
      case result.category.knownVirus: identified = "Identified as virus"; break;
      case result.category.knownCompromised: identified = "Identified as compromised"; break;
    }
    activities.push(
      {
        id: id++,
        date: formatDate(result.dateBecameSpammy),
        time: formatTime(result.dateBecameSpammy),
        icon: <CloseIcon />,
        description: identified
      }
    );
  }
  if (result.recentFeedbackDetails.lastBlock) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.recentFeedbackDetails.lastBlock),
        time: formatTime(result.recentFeedbackDetails.lastBlock),
        icon: <FingerprintIcon />,
        description: "Last report"
      }
    );
  }
  if (result.dateBecameLegit) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.dateBecameLegit),
        time: formatTime(result.dateBecameLegit),
        icon: <CheckCircleIcon />,
        description: "Became legitimate"
      }
    );
  }
  if (result.dateLastSeen) {
    activities.push(
      {
        id: id++,
        date: formatDate(result.dateLastSeen),
        time: formatTime(result.dateLastSeen),
        icon: <VisibilityIcon />,
        description: "Last seen"
      }
    );
  }
  activities = activities.map(activity => {
    return {
      ...activity,
      description: <Typography variant="subtitle1">{activity.description}</Typography>,
      subtitle: <Typography variant="body1">{activity.subtitle}</Typography>,
      content: <Typography variant="caption">{activity.subtitle}</Typography>
    }
  });

  return (
    <React.Fragment>
    <Container style={{display:'flex'}}>
      <Timeline activities={activities} style={{flex:1}}/>
      <Table size="small" aria-label="Threat Analysis"  style={{flex:6}}>
        <TableBody>
          <TRow label="Fingerprint Type" data={result.description} />
          <TRow label="Threat Name" data={result.subcategory} skipEmpty={true} />
          <TRow label="Active In Authority" data={result.activeThreat && (<span>Yes</span>)||(<span>No</span>)} />
          <TRow label="Feedback Volume" data={result.recentFeedbackDetails.feedbackVolume} skipEmpty={true} />
          <TRow label="% Of Reports Were Complaints" data={result.recentFeedbackDetails.percentageBlocks} percent={true} />
          <TRow label="% Of Reports Were Spam Traps" data={result.recentFeedbackDetails.percentageBlocksSpamTraps} percent={true} />
          <TRow label="# Service Providers Seeing Spam Trap Feedback" data={result.recentFeedbackDetails.orgCountSpamTraps} />
          <TRow label={result.algorithm == 8 ? "Domain" : "Hostname"} data={result.algorithmDetails.host} skipEmpty={true} />
          <TRow label="Registrar" data={result.algorithmDetails.registrar !== 'www.alexa.com' ? result.algorithmDetails.registrar : ""} skipEmpty={true} />
          <TRow label="IP Address" data={result.algorithmDetails.address} skipEmpty={true} />
          <TRow label="Spam Call To Action" data={result.algorithmDetails.type} skipEmpty={true} />
          <TRow label="Suspect Reason" data={result.suspectReason} skipEmpty={true} />
        </TableBody>
      </Table>
    </Container>
    </React.Fragment>
  );
};

export const TRow = ({label, data, skipEmpty=false, percent=false}) => {
  if (!data && skipEmpty) {
    return null;
  }
  return (
          <TableRow>
            <TableCell component="th" scope="row">
              {label}
            </TableCell>
            <TableCell>{data}{percent && "%"}</TableCell>
          </TableRow>
  );
};

export default AlgorithmResult;
