import { useEffect } from 'react';
import { useRequest } from './context/RequestProvider';
import { authenticating, loading, complete, error, auth_error } from './context/ResultsProvider';
import { useAuth0 } from '@auth0/auth0-react';


export const useApiResult = (request, dispatch) => {
  const requester = useRequest();
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (request === null || request === undefined || request.length < 1) return; // null, undefined, [] 
    if (!isAuthenticated) return;

    (async () => {
      let token;
      try {
        dispatch(authenticating());
        token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        });
      } catch (e) {
        console.error(e);
        dispatch(auth_error(e.message));
        return;
      }
      if (!(request instanceof Array)) request = [request];
      for (const r of request) {
        r.params.headers = {...r.params.headers, Authorization: `Bearer ${token}`};
        let response;
        try {
          response = await requester(r.url, r.params);
        } catch (e) {
          console.error(e);
          dispatch(error(r.id, e.message));
          return;
        }
        if (response.ok) {
          const res = (response.status != 201) ? await response.json() : {};
          dispatch(complete(r.id, res));
        } 
        else {
          const fail = await response.text();
          dispatch(error(r.id, fail));
        }
      }
    })();

  }, [request, requester, getAccessTokenSilently, isAuthenticated, dispatch]);

};

export default useApiResult;
