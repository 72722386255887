import React, {useState, useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloseIcon from '@material-ui/icons/Close';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {AnalysisHeader, Message, MsgFile} from '../Input';
import { useResults } from '../context/ResultsProvider';
import { loading, useSupport, processSupport, setSupportForm, showSupport, resetSupport, getSupportDescription } from '../context/SupportProvider';
import useApiResult from '../useApiResult';
import FormLabel from '@proofpoint/fuse-ui/components/FormLabel';
import Button from '@proofpoint/fuse-ui/components/Button';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import TextArea from '@proofpoint/fuse-ui/components/TextArea';
import TextField from '@proofpoint/fuse-ui/components/TextField';
import Grid from '@material-ui/core/Grid';
import Loading from './Loading';

export const SupportButton = ({setFlash}) => {
  const [results] = useResults();
  const [supportResults, dispatch] = useSupport();
  
  const setShowSupport = () => {
    setFlash({open:false});
    dispatch(showSupport(true));
  }

  const cancel = (event) => {
    setFlash({open:false});
    dispatch(showSupport(false));
  };

  if (results.loading) {
      return (<Button loading={true}>Open Support Case</Button>);
  }
  if (results.done && !supportResults.show) {
    return(
      <Button onClick={setShowSupport} aria-label="Open Support Case" darkBackgroundTheme="primary" icon={<HelpOutlineIcon htmlColor={'#fafafa'}/>}>Open Support Case</Button>
    );
  }
  if (results.done && supportResults.show) {
    return(
      <Button onClick={cancel} aria-label="Cancel Support Case" darkBackgroundTheme="alternative" icon={<CloseIcon htmlColor={'#fafafa'}/>}>Cancel Support Case</Button>
    );
  }
  return null;
}

const initForm = {contact_name: "", contact_email: "", contact_company: "", issue_summary: "", additional_details: ""};

const SupportForm = ({setFlash}) => {
  const [results] = useResults();
  const [supportResults, dispatch] = useSupport();
  const { user } = useAuth0();
  const [verified, setVerified] = useState(null);
  
  useApiResult(supportResults.request, dispatch);  
  
  useEffect(() => {
    if (supportResults.form.contact_company == "") {
      dispatch(setSupportForm({target: {name:"contact_company", value:user.name}}));
    }
  }, [supportResults.form.contact_company])
  
  useEffect(() => {
    if (supportResults.done) {
      setFlash({severity:"success", message:"Support request sent", open:true});
      dispatch(resetSupport());
    }
  }, [supportResults.done])

  useEffect(() => {
    if (supportResults.error) {
      let error_message;
      try {
        const jr = JSON.parse(supportResults.error);
        error_message  = (Object.hasOwn(jr, 'message')) ? jr.message : JSON.stringify(jr);
      } catch (e) {
        error_message = supportResults.error
      }
      setFlash({severity:"error", message:error_message, open:true});
    }
  }, [supportResults.error]);
  
  const handleChange = (event) => {
    dispatch(setSupportForm(event));
  };

  const openSupport = (event) => {
    setFlash({open:false});
    if (verifyForm("supportForm")) {
      dispatch(loading());
      dispatch(processSupport(results));
    }
  }
  
  const verifyForm = (form) => {
    let isComplete = true;
    for (const input in supportResults.form) {
      if (document.forms[form].elements[input]?.required && supportResults.form[input] === "") isComplete = false;
    }
    setVerified(isComplete);
    return isComplete;
  }

  const hasError = (element) => {
    if (verified === null) return false;
    if (element.match(/email/i) !== null) return (supportResults.form[element].match(/\S+@\S+\.\S+/) === null)
    return (supportResults.form[element] === "");
  }

  if (!supportResults.show) return null;
  
  if (!results.data) return null;
 
  
  return (
  <>
    <Loading show={supportResults.loading}/>
    <Card>
      <CardContent>
        <form id="supportForm" name="supportForm">
          <Grid container spacing={4}>
            <Grid item={true} xs={3}>
              <TextField
                label="Contact Name"
                name="contact_name"
                placeholder="First Last"
                fullWidth={true}
                required={true}
                inputProps={{"maxLength":50}}
                onChange={handleChange}
                error={hasError("contact_name")}
                helperText={hasError("contact_name")?"Required":""}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextField
                label="Contact Email"
                name="contact_email"
                placeholder="you@yourcompany.com"
                fullWidth={true}
                required={true}
                inputProps={{"maxLength":100}}
                onChange={handleChange}
                error={hasError("contact_email")}
                helperText={hasError("contact_email")?"A valid email address is required":"We will use this address to communicate about the issue"}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextField
                label="Contact Company"
                name="contact_company"
                fullWidth={true}
                required={true}
                inputProps={{"maxLength":50}}
                defaultValue={supportResults.form.contact_company}
                onChange={handleChange}
                error={hasError("contact_company")}
                helperText={hasError("contact_company")?"Required":""}
              />
            </Grid>
            <Grid item={true} xs={3}>
              <TextField
                label="Contact Phone"
                name="contact_phone"
                placeholder="123 456 7890"
                fullWidth={true}
                inputProps={{"maxLength":20}}
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextField
                label="Issue Summary"
                name="issue_summary"
                placeholder="Short issue description"
                fullWidth={true}
                required={true}
                inputProps={{"maxLength":96}}
                onChange={handleChange}
                error={hasError("issue_summary")}
                helperText={hasError("issue_summary")?"Required":""}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextArea
                label="Additional Details"
                placeholder="Any additional information that will help us address your issue"
                fullWidth={true}
                name="additional_details"
                rows={3}
                onChange={handleChange}
              />
            </Grid>
            <Grid item={true} xs={12}>
              <TextArea
                label="The following will be sent to Support"
                fullWidth={true}
                name="content"
                value={getSupportDescription(results)}
                rows={6}
                maxRows={6}
                disabled={true} 
              />
            </Grid>
            <Attachment type={results.input} onChange={handleChange}/>
            <Grid item={true} xs={12}>
              <Button onClick={openSupport} aria-label="Submit" darkBackgroundTheme="primary">Submit</Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  </>);
}

const Attachment = ({type, onChange}) => {
  switch (true) {
    case type instanceof Message:
    case type instanceof MsgFile:
      return (
        <Grid item={true} xs={12} style={{ alignItems: 'center', display: 'inline-flex' }}>
          <AttachFileIcon/> <Typography variant="subtitle2">Submitted email message</Typography>
        </Grid>
      );
    case type instanceof AnalysisHeader:
      return (
        <Grid item={true} xs={12} style={{ alignItems: 'center', display: 'inline-flex' }}>
          <FormLabel>Optional message sample <input id="sample_file" type="file" name="sample_file" onChange={onChange} /></FormLabel>
        </Grid>
      );
    default:
      return null;
  }
}

export default SupportForm;
