import React from 'react';
import EngineMap from '../EngineMap'; 
import { postSupportRequest } from '../requests';
import Input, {IP, CIDR, AnalysisHeader, Message, MsgFile} from '../Input';

const Support = React.createContext();

const REQ_SUPPORT   = "APP/ACTION/SUPPORT";
const REQ_AUTHN     = "APP/ACTION/AUTHN";
const REQ_LOADING   = "APP/ACTION/LOADING";
const REQ_COMPLETE  = "APP/ACTION/COMPLETE";
const REQ_ERROR     = "APP/ACTION/ERROR";
const AUTHN_ERROR   = "APP/ACTION/AUTHN_ERROR";
const SET_SUPPORT   = "APP/ACTION/SET_SUPPORT";
const SHOW_SUPPORT  = "APP/ACTION/SHOW_SUPPORT";
const RESET_SUPPORT = "APP/ACTION/RESET_SUPPORT";

export const processSupport = (results) => ({type: REQ_SUPPORT, results});
export const setSupportForm = (event)   => ({type: SET_SUPPORT, event});
export const showSupport    = (bool)    => ({type: SHOW_SUPPORT, bool});
export const resetSupport   = ()        => ({type: RESET_SUPPORT});
export const loading        = ()        => ({type: REQ_LOADING});

export const describeIpScore = (score) => {
  switch (score) {
    case "127.0.0.2":
      return 'CSI is currently publishing the reputation of this IP address as poor';
    case "127.0.0.3":
      return 'CSI is currently publishing the reputation of this IP address as suspect';
    case "127.0.0.4":
      return 'CSI is currently categorizing this IP address as Known Mail Forwarder.';
    case "127.0.0.5":
      return 'CSI is currently categorizing this IP address as belonging to a Cloud provider.';
    default:
      return 'CSI is not currently publishing reputation for this IP address.';
  }
}

export const getSupportDescription = (results) => {
  let preamble, description;
  if (!results) return "";
  switch (true) {
    case results.input instanceof IP:
      preamble = "IP address in question:";
      description = `${results.input.raw_ip}
${results.data[0].ipAddress} ${describeIpScore(results.data[0].score)}
----------`;
      break;
    case results.input instanceof CIDR:
      preamble = "IP block in question:";
      const scored = results.data.filter(i => i.score !== '127.0.0.0').map(i => `${i.ipAddress} ${describeIpScore(i.score)}`).join("\n");
      const size = 2**(32 - parseInt(results.input.mask));
      const count = results.data.filter(i => i.score === '127.0.0.2').length;
      description = `${results.input.raw_cidr}

Number of IPs in block: ${size.toString()}
----------
Number of POOR IPs in block: ${count.toString()}
----------
${scored}

Unlisted IPs are not shown
----------`;
      break;
    case results.input instanceof AnalysisHeader:
      preamble = "Cloudmark analysis string in question:";
      description = results.input.getFoldedAnalysisString()
      const algs = results.data.algorithmResults.filter(e => e.poorReputation).map(e => `${e.id} ${EngineMap(e.algorithm)[0]}`);
      if (algs.length > 0) {
        description += `\r\n\r\n----------\r\n${algs.join("\r\n")}\r\n`
      }
      break;
    case results.input instanceof Message:
    case results.input instanceof MsgFile:
      preamble = "Cloudmark content string in question:";
      description = results.data.algorithmResults.filter(e => e.poorReputation).map(e => `${e.id} ${EngineMap(e.algorithm)[0]}`).join("\r\n");
      if (description.length === 0) {
        description = "[No algorithms found to be spammy in this analysis]"
      }
      description += `\r\n\r\n----------\r\n${results.input.content}\r\n`
      break;
    default:
      preamble = description = "";
      break;
  }
  return `${preamble}
----------
${description}
`;

};

export const initSupportState = {
  show: false,
  done: false,
  loading: false,
  form: {
    contact_name: "",
    contact_email: "",
    contact_company: "",
    contact_phone: "",
    issue_summary: "",
    additional_details: "",
    sample_file: {}
  },
  error: null,
  data :null,
  request: null
};

export const supportReducer = (state = {...initSupportState}, action) => {
  switch (action.type) {
    case SET_SUPPORT:
      if (action.event.target.name === "sample_file") {
        const maxFileSize = parseInt(process.env.REACT_APP_MAX_FILESIZE_MB)*(1024**2);
        const input = Input.detectType(action.event.target.files[0]);
        if (input.file.size > maxFileSize) {
            state.error = "Maximum file size for a message is "+(maxFileSize/(1024**2))+"Mb";
        } else {
          state.form.sample_file = input;
          
          input.toBase64().then(binary => {
            state.form.sample_file.content = binary;
            console.log(binary);
          })
          .catch(e => {
            log.error(e);
            state.error = e;
          });
          
        }
      } else {
        state.form[action.event.target.name] = action.event.target.value;
      }
      return {...state};
    case SHOW_SUPPORT: 
      return {...state, show: action.bool};
    case RESET_SUPPORT: 
      return {...initSupportState};
    case REQ_SUPPORT:
      return {
        ...state,
        request: postSupportRequest(state.form, action.results),
        loading: true,
        done: false,
        error: null
      };
    case REQ_AUTHN:
    case REQ_LOADING:
      return {
        ...state,
        loading: true
      };
    case REQ_ERROR:
      state.error = action.error;
      // Fall through into REQ_COMPLETE
    case REQ_COMPLETE:
      return {
        ...state,
        data: action.data,
        done: true,
        loading: false
      };
    case AUTHN_ERROR:
      return {
        ...state,
        error: action.error,
        loading: false,
        done: true
      };
  }
};

export const useSupport = () => React.useContext(Support);

export const SupportProvider = ({ children, initialState, reducer }) => {
  const [globalState, dispatch] = React.useReducer(reducer, initialState);
  return (
    <Support.Provider value={[globalState, dispatch]}>{children}</Support.Provider>
  );
};


