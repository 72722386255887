import { AnalysisHeader, Message, MsgFile } from './Input';
import { getSupportDescription } from './context/SupportProvider';

export const BASE_URL = process.env.REACT_APP_INSIGHT_FEATURES_URL;
export const SUPPORT_ADDRESS = process.env.REACT_APP_SUPPORT_ADDRESS;

const createUrl = (base, path) => `${base}${path}`;

const buildRequest = (path, oRequest, id="") => {
  const url = createUrl(BASE_URL, path);
  return {
    url: url,
    params: oRequest,
    id,
  };
};

export const getCartridges = () => buildRequest("/features/v2/cartridges",{ method: "GET", mode:"cors"});

export const getCartridgeVersion = (cv) =>  buildRequest(`/features/v2/cartridges/${cv}`,{ method: "GET", mode:"cors"});

export const getIPDetail = (ip) =>  buildRequest(`/features/v2/ip/${ip.normalize()}`,{ method: "GET", mode:"cors"});

export const getCsiIP = (ip) =>  buildRequest(`/features/v2/csi/${ip.normalize()}`,{ method: "GET", mode:"cors"});

export const getCsiCidr = (cidr) =>  buildRequest(`/features/v2/csi/${cidr.raw_cidr}`,{ method: "GET", mode:"cors"});

export const postAuthorityAnalysis = (analysis) => {
  const payload = `{"analysis": "${analysis.getForcedAnalysisString()}"}`
  return buildRequest("/features/v2/authority-analysis",{ method: "POST", mode:"cors", body: payload});
}

export const postMessageAnalysis = (message, cartridge) => {
  const formData = new FormData();
  formData.append('cartridge', cartridge);
  const content = message.content || message.file
  formData.append('rfc822', content);
  return buildRequest("/features/v2/message",{ 
    method: "POST", 
    mode:"cors", 
    body: formData
  });
}

export const postSupportRequest = (form, results) => {
  let multipart = (results.input instanceof Message||results.input instanceof MsgFile);
  let outer, inner;

  try {
    outer = (self||window).crypto.randomUUID();
    inner = (self||window).crypto.randomUUID();
  } catch (e) {
    console.error(`No client support for crypto function: ${e}`);
    outer = "outer"+Date.now().toString();
    inner = "inner"+Date.now().toString();
  }

  const message_body =`Product: Cloudmark Authority

Contact Name: ${form.contact_name}
Contact Email: ${form.contact_email}
Contact Company: ${form.contact_company}
Contact Phone: ${form.contact_phone}

Summary
${form.issue_summary}

Description
${form.additional_details}

${getSupportDescription(results)}
`;

  let content, mime, fname, encoding;
  if (results.input instanceof Message) {
    content = results.input.content;
    mime = "text/plain";
    fname = `sample-${Date.now().toString()}.txt`;
    encoding = "Content-transfer-encoding: 8bit";
  }

  if (results.input instanceof MsgFile) {
    content = results.input.base64;
    mime = results.input.file.type;
    fname = results.input.file.name;
    encoding = "Content-transfer-encoding: base64";
  }

  if (results.input instanceof AnalysisHeader && form.sample_file.content) {
    content = form.sample_file.content;
    mime = form.sample_file.file.type;
    fname = form.sample_file.file.name;
    encoding = "Content-transfer-encoding: base64";
    multipart = true;
  }

  const multipart_body = `--${outer}
Content-Type: text/plain
Content-Disposition: inline
Content-Description: user message

${message_body}

--${outer}
Content-Type: multipart/mixed; boundary=${inner}
Content-Disposition: attachment
Content-Description: multipart

--${inner}
Content-Type: ${mime}
Content-Disposition: attachment
Content-Description: ${fname}
${encoding}

${content}

--${inner}--
--${outer}--
`;
  const subject = `subject: P4 ${form.issue_summary}`;
  const content_type = (multipart)? `Content-Type: multipart/mixed; boundary=${outer}` : 'Content-Type: text/plain';
  const content_description = (multipart)? "Content-Description: multipart-message" : "Content-Description: Simple support request";
  const message = (multipart)? multipart_body : message_body;
  const payload = {header: [subject, content_type, content_description], from: form.contact_email, recipient: [SUPPORT_ADDRESS], body: message};
  return buildRequest("/support/v1/email",{ method: "POST", mode:"cors", headers: {"content-type": "application/json"}, body: JSON.stringify(payload)});
}

export const postAssertionRequest = (action, results, id) => {
  const formData = new FormData();
  let customerId = id.customerId
  let content;
  switch (true) {
    case results.input instanceof Message:
      content = results.input.content;
      break;
    case results.input instanceof MsgFile:
      content = results.input.file;
      break;
    case results.input instanceof AnalysisHeader:
      customerId += "-hdr";
      content = `To: <nobody@cloudmark.com>\r\n`
      content += `From: <${id.reporter}@feedback.cloudmark.com>\r\n`
      content += `Date: ${(new Date).toUTCString()}\r\n`
      content += `Subject: API Submiited Analysis String (${Date.now().toString()})\r\n`
      content += `Content-type: text/plain; charset="UTF-8"\r\n`
      content += `X-Portal-Reporter: ${id.reporter}\r\n`
      content += `X-Portal-Analysis: ${results.input.getFoldedAnalysisString()}\r\n`
      content += `X-Portal-Authority: ${results.data.cartridgeVersion}\r\n`
      content += `\r\n`
      content += `no content\r\n`;
      break;
    default:
      console.error("Invalid input type to block/unblock.");
      return;
  }
  formData.append('email', content);
  formData.append('reporter', id.reporter);
  formData.append('customerId', customerId);
  const uri = `/feedback/v2/email/${action}`;
  return buildRequest(uri,{ 
    method: "POST", 
    mode:"cors", 
    body: formData
  }, id.reporter);
}
