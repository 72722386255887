import React from "react";
import FormControl from '@proofpoint/fuse-ui/components/FormControl';
import Snackbar from '@proofpoint/fuse-ui/components/Snackbar';
import RadioGroup from '@proofpoint/fuse-ui/components/RadioGroup';
import Alert from '@proofpoint/fuse-ui/components/Alert';
import { useCartridges } from '../context/CartridgesProvider';
import { useCartridgeVersion } from '../context/CartridgeVersionProvider';
import { processCartridges, setSelectedCartridge } from '../context/ResultsProvider';
import useApiResult from '../useApiResult';
import Cartridge from "./Cartridge";

const Cartridges = () => {
  const [cState, cDispatch] = useCartridges();
  const [selectedCartridge, dispatchCartridge] = useCartridgeVersion();
  const [isSnackOpen, setIsSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");

  useApiResult(cState.request, cDispatch);
  useApiResult(selectedCartridge.request, dispatchCartridge);
  
  React.useEffect(() => {
    if (cState.data === null && !cState.loading) {
      cDispatch(processCartridges());
    }
  },[])
  
  const handleCartridgeSelection = (e) => {
    dispatchCartridge(setSelectedCartridge(e.target.value));
  }
  
  const handleSnackClose = (e, reason) => {
    if (reason === 'clickaway') return;
    setIsSnackOpen(false);
  }

  const handleSnackClick = () => {
    setIsSnackOpen(false);
  }
  
  React.useEffect(() => {
    if (selectedCartridge?.data?.major_version && cState.data
      && selectedCartridge.selectedCartridge !== selectedCartridge.data.major_version 
      && cState.data.some((e) => e.major_version === selectedCartridge.data.major_version)
    ) {
      setSnackMessage(`Auto-detected cartridge: ${selectedCartridge.data.major_version}`);
      setIsSnackOpen(true);
      dispatchCartridge(setSelectedCartridge(selectedCartridge.data.major_version));
    }
  }, [selectedCartridge.data, cState.data]);
  
  if (!cState.done) return <></>;
  if (cState.error) return (<Alert severity={'error'} title={cState.error} >
    This device is unable to connect to Customer Accuracy Tools backend services. Please check your internet connection and reload the page.
    <br/><br/>
    If the problem persists, please contact <a href="https://www.cloudmark.com/en/support">Cloudmark Support</a>.
  </Alert>);
  if (cState.data.length < 1) return (<Alert severity={'warning'} title="Service Unavailable" >
    We are unable to find any active cartridges to run your check against.
    <br/><br/>
    If the problem persists, please contact <a href="https://www.cloudmark.com/en/support">Cloudmark Support</a>.
  </Alert>);

  const cartridges = cState.data.sort((a, b) => (parseInt(b.major_version) - parseInt(a.major_version)));
  return (
    <FormControl component="fieldset" style={{ margin: 24 }}>
      <RadioGroup
        aria-label="Select Cartridge"
        name="cartridge_version"
        onChange={handleCartridgeSelection}
        style={{ margin: '4px 0' }}>
          {cState.error !== null
          ? <p>Error fetching cartridges: {cState.error}</p>
          : cartridges.map(item => (<Cartridge key={item.major_version} cartridge={item} checked={item.major_version===selectedCartridge.selectedCartridge}/>))}
      </RadioGroup>
      <Snackbar 
        open={isSnackOpen} 
        onClose={handleSnackClose} 
        onActionButtonClick={handleSnackClick}
        message={snackMessage}
        actionCloseButton
      />
    </FormControl>
  );  
}

export default Cartridges;
