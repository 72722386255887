class Input {
  static ip_rx = /^((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4]\d|25[0-5])$/
  static cidr_rx = /^((1?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(1?\d{1,2}|2[0-4]\d|25[0-5])\/([12]?\d|3[0-2])$/
  static header_rx = /(^([\x21-\x7E]+):(.*$(?:(?:(?:\r\n)|\r|\n)^[\x09\x20]+.*$)*))((?:(?:\r\n)|\n){2,}.)?/gm
  static cmae_hdr_rx = /\b(?<no_content>nc)\b|\b(?<signature>(?<verdict>[ap])=(?<sig>\S+):(?<engine>\d{1,3}))|\ba?wl=(?:(?:host|hdr|env|body):)?(?<whitelist>\d+)\b|\bcv=(?<cartridge_version>[\w+\/=-]+)\b|\bsm=(?<scoring_method>[0-4])\b|\btr=(?<truncated>[01])\b|\bc=(?<complete>[01])\b|\bv=(?<version>\d+\.\d+)\b|^\b(?<field_name>[xX][\x21-\x7E]+):/g

  static detectType(value) {
    if (value == null || value === "" || value.length < 1) return null;
    if (value instanceof File) return new MsgFile(value)
    if (value.trim().match(this.ip_rx)) {
      return new IP(value.trim())
    }
    if (value.trim().match(this.cidr_rx)) {
      return new CIDR(value.trim());
    }
    const m = [...value.matchAll(this.header_rx)];
    if (m.length > 0) {
      return this.parseMessage(m, value);
    }
    const authority_header = this.parseAuthorityHeader(value);
    if (authority_header.analysisLikeness() >= 0.2) return authority_header;
    return new Message(value, false, true);
  }

  static parseMessage(hdrMatches, value) {
    let headers = {};
    let likeness = 0;
    let authority_header = new AnalysisHeader()
    for (const match of hdrMatches) {
      headers[match[2].toLowerCase()] = match[3]
      if (match[4]!=undefined && headers.hasOwnProperty("from") && headers.hasOwnProperty("date")) {
        let m = new Message(value, true, false);
        m.SetAnalysis(authority_header)
        return m;
      }
      const test_header = this.parseAuthorityHeader(match[0]);
      if (test_header.analysisLikeness() >= likeness) {
        likeness = test_header.analysisLikeness();
        authority_header = test_header;
      }
    }
    if (authority_header.analysisLikeness() >= 0.2) return authority_header;
    return new Message(value, false, false);
  }

  static parseAuthorityHeader(value) {
    const matches = value.matchAll(this.cmae_hdr_rx);
    let as = new AnalysisHeader();
    as.raw_value = value;

    for (const match of matches) {
      this.appendHeader(as, match);
    }
    return as;
  }

  static appendHeader(as, match) {
    const toBool = function(str) {
      return (str === "1" || str === 1);
    }

    if (match.groups.whitelist !== undefined) {
      as.whitelist.push(match.groups.whitelist);
    }
    if (match.groups.no_content !== undefined) {
      as.no_content = true;
    }
    if (match.groups.cartridge_version !== undefined) {
      as.cartridge_version = match.groups.cartridge_version;
    }
    if (match.groups.scoring_method !== undefined) {
      as.scoring_method = match.groups.scoring_method;
    }
    if (match.groups.truncated !== undefined) {
      as.truncated = toBool(match.groups.truncated);
    }
    if (match.groups.complete !== undefined) {
      as.complete = toBool(match.groups.complete);
    }
    if (match.groups.version !== undefined) {
      as.version = match.groups.version;
    }
    if (match.groups.field_name !== undefined) {
      as.field_name = match.groups.field_name;
    }

    if (match.groups.signature !== undefined) {
      as.signatures.push({
        signature: match.groups.signature,
        verdict: match.groups.verdict,
        sig: match.groups.sig,
        engine: match.groups.engine,
      });
    }
  }

}

class InputType {}

class IP extends InputType {
  label = "IP";
  raw_ip = null;
  constructor(ip) {
    super();
    this.raw_ip = ip;
  }

  normalize() {
    return this.raw_ip.split('.').map(e => parseInt(e).toString()).join('.');
  }
}

class CIDR extends InputType {
  label = "CIDR";
  raw_cidr = null
  in_range = null;
  mask = null;
  ip_parts = [];
  bits = null;
  bit_mask = null;

  constructor(cidr) {
    super();
    this.raw_cidr = cidr;
    const split = this.raw_cidr.split("/");
    this.ip_parts = split[0].split(".");
    this.mask = parseInt(split[1]);
    this.bits = Int32Array.from(this.ip_parts, x => parseInt(x));
    this.bit_mask = ((2**(this.mask))-1<<(32-this.mask))
    this.in_range = (this.mask >= parseInt(process.env.REACT_APP_MAX_CIDR_SLASH));
  }
  
  arrayToInt() {
    let intr = 0;
    for (let i = 0; i < this.bits.length ; i++) {
      intr += this.bits[i] << ((3-i)*8);
    }
    return intr;
  }

  intToArray(n) {
    let arr = [];
    for (let i = 3; i >= 0; i--) {
        arr[i] = n>>(i*8)&255
    }
    return arr.reverse();
  }

  getStartIP() {
    return this.arrayToInt() & this.bit_mask;
  }

  getEndIP() {
    return this.arrayToInt() | ~this.bit_mask;
  }

  length() {
    return this.getEndIP() - getStartIP();
  }

  [Symbol.iterator]() {
    let current = this.getStartIP();
    const end = this.getEndIP(); 

    return {
      next: () => {
        if (current <= end) {
          let ip = new IP(this.intToArray(current).join("."));
          current++;
          return {value: ip, done: false}
        } else {
          return {done: true}
        }
      }
    }
  }
  
}

class AnalysisHeader extends InputType {
  label = "Analysis Header";
  ase_sig = /\b[ap]=QhnmdGnfZl10ibMM-eB4:22\b/
  whitelist = null;
  signatures = null;
  no_content = null;
  cartridge_version = null;
  scoring_method = null;
  truncated = null;
  complete = null;
  version = null;
  field_name = null;
  raw_value = null;
  analysis_string = null;
  missing = [];
  likeness = 0;

  constructor() {
    super();
    this.whitelist = new Array();
    this.signatures = new Array();
  }

  urlEncodedCV() {
    return this.cartridge_version.replace('/','_').replace('+','-');
  }

  isCompleteAnalysisString() {
    if (this.cartridge_version === null) {
      return false;
    }
    if (this.scoring_method === null) {
      return false;
    }
    if (this.version === null) {
      return false;
    }
    if (this.complete === null) {
      return false;
    }
    if (parseFloat(this.cartridge_version) > 2 && this.truncated === null) {
      return false;
    }
    return !(this.whitelist === null && this.signatures === null && this.no_content === null);
  }

  containsASESignature() {
    return this.ase_sig.test(this.analysis_string); 
  }

  analysisLikeness() {
    if (this.likeness > 0) return this.likeness;
    let likeness = 0;
    let expected = ['cartridge_version', 'scoring_method', 'version', 'complete'] ;
    if (parseFloat(this.cartridge_version) > 2) expected.push('truncated');
    if (this.whitelist.length === 0  && this.signatures.length === 0 && this.no_content === null) {
      this.missing.push('whitelist', 'signatures', 'no_content');
      return 0;
    }
    likeness++;
    for (const expects of expected) {
      if (this[expects] !== null) {
        likeness++;
      } else {
        this.missing.push(expects); 
      }
    }
    this.likeness = likeness/(expected.length+1);
    return this.likeness;
    
  }

  getAnalysisString() {
    if (this.analysis_string !== null) return this.analysis_string;
    if (this.raw_value === null) return "";
    let tmp_str = this.raw_value.trim();
    tmp_str = tmp_str.replaceAll(/[\n\r]/gm, "");
    if (this.field_name !== null) tmp_str = tmp_str.substring(this.field_name.length+1)
    this.analysis_string = tmp_str;
    return this.analysis_string;
  }

  getFoldedAnalysisString() {
    const max = 72;
    let wrapped = "";
    let wrap_length = 0;
    if (this.raw_value === null) return "";
    let tmp_str = this.raw_value.trim();
    tmp_str = tmp_str.replaceAll(/[\n\r]/gm, "");
    const words = tmp_str.split(' ');
    for (const word of words) {
      if ((wrapped.length - wrap_length + word.length) > max) {
        wrap_length = wrapped.length;
        wrapped += "\r\n  " + word + " ";
      } else {
        wrapped += word + " ";
      }
    }
    if (this.field_name !== null) wrapped = wrapped.substring(this.field_name.length+1);
    return wrapped.trim();
  }
  
  getForcedAnalysisString() {
    let str = this.getAnalysisString();
    return str.replaceAll(/a=/g, "p=")
  }
}

class Message extends InputType {
  label = "Message";
  isFullRfc822;
  isBodyOnly;
  analysis;
  content;
  containsAnalysisHeader = false;

  constructor(content, isFullRfc822, isBodyOnly) {
    super();
    this.content = content;
    this.isFullRfc822 = isFullRfc822;
    this.isBodyOnly = isBodyOnly;
  }

  SetAnalysis(analysis) {
    this.analysis = analysis;
    this.containsAnalysisHeader = this.analysis.analysisLikeness() >= 1;
  }
}

class MsgFile extends InputType {
  label = "File";
  file;
  content = null;
  base64 = null;
  inner = null;

  constructor(file) {
    super();
    this.file = file;
  }

  readFile() {
    return this.file.text();
  }

  toDataUrl() {
    const blob = this.file;
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  }

  toBase64() {
    return this.toDataUrl().then(text => text.slice(text.indexOf(",")+1));
  }

  setContent(text) {
    var self = this;
    this.content = text;
    this.inner = Input.detectType(text);
    this.toBase64().then(b64 => self.base64 = b64);
  }

}

export {Input as default, InputType, IP, CIDR, AnalysisHeader, Message, MsgFile};
