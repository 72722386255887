import React from 'react';
import Loading from './Loading';
import Verdict from './Verdict';
import { useResults } from '../context/ResultsProvider';
import AlgorithmResult from './AlgorithmResult';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@proofpoint/fuse-ui/components/Typography';
import Flash from './Flash';

const MessageAnalysis = () => {
  const [analysis] = useResults();
  const [flash, setFlash] = React.useState({severity:"notice", message:"", open:false});
 
  React.useEffect(() => {
    if (typeof analysis.input.containsASESignature === "function" && analysis.input.containsASESignature()) {
      setFlash({severity:"info", message:"The fingerprint (QhnmdGnfZl10ibMM-eB4) is for a message that is classified as spam through Automated Signals Extraction. Please log a support ticket with the full message content to request remediation", open:true});
    }
    else {
      setFlash({...flash, open:false});
    }
  },[analysis.input.containsASESignature, analysis.data]);

  if (analysis.loading) return <Loading />;
  if (analysis.error !== null) return <Typography variant="subtitle1" gutterBottom color="error">{analysis.error}</Typography>;
  if (analysis.data === null) return <p>No data</p>;
  if (analysis.data.algorithmResults === undefined) return null;
 
  const algorithmResults = [...analysis.data.algorithmResults].sort((a,b) => {
    if (a.poorReputation && a.poorReputation === b.poorReputation) return 1;
    if (a.poorReputation && a.poorReputation !== b.poorReputation) return -1;
  });
  return (    
    <React.Fragment>
      <Flash severity={flash.severity} message={flash.message} open={flash.open} setOpen={setFlash}/>
      <TableContainer>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2}/>
              <TableCell><Typography component="h6" variant="h6">Fingerprints Calculated (max. 50)</Typography></TableCell>
              <TableCell><Typography component="h6" variant="h6">Current Verdict: <Verdict results={algorithmResults} /></Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {algorithmResults.map(result => <AlgorithmResult key={result.id} result={result} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );  
}

export default MessageAnalysis;
